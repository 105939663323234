@import '@angular/cdk/overlay-prebuilt.css';
@import '../../../styles/recaptcha-override.less';

.cdk-overlay-container,
.norby-embed {
  @import (less) '../../../styles/tailwind/tailwind-output-embeds.css';
  @import '../../../styles/typography.less';
  @import '../../../styles/variables.less';
  @import '../../../styles/feather-overrides.less';
  @import '../../../styles/quill-overrides.less';
  @import '../../../styles/utility.less';

  @link-color: @dark-navy;
  @link-hover-color: @neutral-800;
  @link-active-color: @black;
  @link-hover-decoration: none;
  @link-focus-decoration: none;
  @link-focus-outline: 0;
  @html-selector: html;

  // remove the clear button of a text input control in IE10+
  input::-ms-clear,
  input::-ms-reveal {
    display: none;
  }

  // Suppress the focus outline on elements that cannot be accessed via keyboard.
  // This prevents an unwanted focus outline from appearing around elements that
  // might still respond to pointer events.
  //
  // Credit: https://github.com/suitcss/base
  [tabindex='-1']:focus {
    outline: none !important;
  }

  // Content grouping
  //
  // 1. Add the correct box sizing in Firefox.
  // 2. Show the overflow in Edge and IE.

  hr {
    box-sizing: content-box; // 1
    height: 0; // 1
    overflow: visible; // 2
  }

  // Reset margins on paragraphs
  //
  // Similarly, the top margin on `<p>`s get reset. However, we also reset the
  // bottom margin to use `em` units instead of `em`.
  p {
    margin-top: 0;
    margin-bottom: 1em;
  }

  // Abbreviations
  //
  // 1. remove the bottom border in Firefox 39-.
  // 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
  // 3. Add explicit cursor to indicate changed behavior.
  // 4. Duplicate behavior to the data-* attribute for our tooltip plugin

  abbr[title],
  abbr[data-original-title] {
    // 4
    text-decoration: underline; // 2
    text-decoration: underline dotted; // 2
    border-bottom: 0; // 1
    cursor: help; // 3
  }

  address {
    margin-bottom: 1em;
    font-style: normal;
    line-height: inherit;
  }

  input[type='text'],
  input[type='password'],
  input[type='number'],
  textarea {
    -webkit-appearance: none;
  }

  dt {
    font-weight: 500;
  }

  dd {
    margin-bottom: 0.5em;
    margin-left: 0; // Undo browser default
  }

  blockquote {
    margin: 0 0 1em;
  }

  dfn {
    font-style: italic; // Add the correct font style in Android 4.3-
  }

  b,
  strong {
    font-weight: bolder; // Add the correct font weight in Chrome, Edge, and Safari
  }

  small {
    font-size: 80%; // Add the correct font size in all browsers
  }

  //
  // Prevent `sub` and `sup` elements from affecting the line height in
  // all browsers.
  //

  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }

  //
  // Links
  //

  a:not(.user-theme-button-secondary) {
    background-color: transparent; // remove the gray background on active links in IE 10.
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects; // remove gaps in links underline in iOS 8+ and Safari 8+.

    &:hover {
      color: @link-hover-color;
    }

    &:active {
      color: @link-active-color;
    }

    &:active,
    &:hover {
      text-decoration: @link-hover-decoration;
      outline: 0;
    }

    // https://github.com/ant-design/ant-design/issues/22503
    &:focus {
      text-decoration: @link-focus-decoration;
      outline: @link-focus-outline;
    }

    &[disabled] {
      color: @disabled-color;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  //
  // Code
  //

  pre,
  code,
  kbd,
  samp {
    font-size: 1em; // Correct the odd `em` font sizing in all browsers.
    font-family: @code-family;
  }

  pre {
    // remove browser default top margin
    margin-top: 0;
    // Reset browser default of `1em` to use `em`s
    margin-bottom: 1em;
    // Don't allow content to break outside
    overflow: auto;
  }

  //
  // Figures
  //
  figure {
    // Apply a consistent margin strategy (matches our type styles).
    margin: 0 0 1em;
  }

  //
  // Images and content
  //

  img {
    vertical-align: middle;
    border-style: none; // remove the border on images inside links in IE 10-.
  }

  svg:not(:root) {
    overflow: hidden; // Hide the overflow in IE
  }

  // Avoid 300ms click delay on touch devices that support the `touch-action` CSS property.
  //
  // In particular, unlike most other browsers, IE11+Edge on Windows 10 on touch devices and IE Mobile 10-11
  // DON'T remove the click delay when `<meta name="viewport" content="width=device-width">` is present.
  // However, they DO support emoving the click delay via `touch-action: manipulation`.
  // See:
  // * https://getbootstrap.com/docs/4.0/content/reboot/#click-delay-optimization-for-touch
  // * http://caniuse.com/#feat=css-touch-action
  // * https://patrickhlauke.github.io/touch/tests/results/#suppressing-300ms-delay

  a,
  area,
  button,
  [role='button'],
  input:not([type='range']),
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
  }

  //
  // Tables
  //

  table {
    border-collapse: collapse; // Prevent double borders
  }

  caption {
    padding-top: 0.75em;
    padding-bottom: 0.3em;
    color: @text-color-secondary;
    text-align: left;
    caption-side: bottom;
  }

  th {
    // Matches default `<td>` alignment by inheriting from the `<body>`, or the
    // closest parent with a set `text-align`.
    text-align: inherit;
  }

  //
  // Forms
  //

  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0; // remove the margin in Firefox and Safari
    //   color: inherit;
    //   font-size: inherit;
    //   font-family: inherit;
    //   line-height: inherit;
  }

  button,
  input {
    overflow: visible; // Show the overflow in Edge
  }

  button,
  select {
    text-transform: none; // remove the inheritance of text transform in Firefox
  }

  // 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
  //    controls in Android 4.
  // 2. Correct the inability to style clickable types in iOS and Safari.
  button,
@{html-selector} [type="button"], /* 1 */
[type="reset"],
[type="submit"] {
    -webkit-appearance: button; // 2
  }

  // remove inner border and padding from Firefox, but don't restore the outline like Normalize.
  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  input[type='radio'],
  input[type='checkbox'] {
    box-sizing: border-box; // 1. Add the correct box sizing in IE 10-
    padding: 0; // 2. remove the padding in IE 10-
  }
  input[type='tel'] {
    border: none;
  }

  input[type='date'],
  input[type='time'],
  input[type='datetime-local'],
  input[type='month'] {
    // remove the default appearance of temporal inputs to avoid a Mobile Safari
    // bug where setting a custom line-height prevents text from being vertically
    // centered within the input.
    // See https://bugs.webkit.org/show_bug.cgi?id=139848
    // and https://github.com/twbs/bootstrap/issues/11266
    -webkit-appearance: listbox;
  }

  textarea {
    overflow: auto; // remove the default vertical scrollbar in IE.
    // Textareas should really only resize vertically so they don't break their (horizontal) containers.
    resize: vertical;
  }

  fieldset {
    // Browsers set a default `min-width: min-content;` on fieldsets,
    // unlike e.g. `<div>`s, which have `min-width: 0;` by default.
    // So we reset that to ensure fieldsets behave more like a standard block element.
    // See https://github.com/twbs/bootstrap/issues/12359
    // and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
    min-width: 0;
    margin: 0;
    // Reset the default outline behavior of fieldsets so they don't affect page layout.
    padding: 0;
    border: 0;
  }

  // 1. Correct the text wrapping in Edge and IE.
  // 2. Correct the color inheritance from `fieldset` elements in IE.
  legend {
    display: block;
    width: 100%;
    max-width: 100%; // 1
    margin-bottom: 0.5em;
    padding: 0;
    color: inherit; // 2
    font-size: 1.5em;
    line-height: inherit;
    white-space: normal; // 1
  }

  progress {
    vertical-align: baseline; // Add the correct vertical alignment in Chrome, Firefox, and Opera.
  }

  // Correct the cursor style of incement and decement buttons in Chrome.
  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto;
  }

  [type='search'] {
    // This overrides the extra rounded corners on search inputs in iOS so that our
    // `.form-control` class can properly style them. Note that this cannot simply
    // be added to `.form-control` as it's not specific enough. For details, see
    // https://github.com/twbs/bootstrap/issues/11586.
    outline-offset: -2px; // 2. Correct the outline style in Safari.
    -webkit-appearance: none;
  }

  //
  // remove the inner padding and cancel buttons in Chrome and Safari on macOS.
  //

  [type='search']::-webkit-search-cancel-button,
  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  //
  // 1. Correct the inability to style clickable types in iOS and Safari.
  // 2. Change font properties to `inherit` in Safari.
  //

  ::-webkit-file-upload-button {
    font: inherit; // 2
    -webkit-appearance: button; // 1
  }

  //
  // Correct element displays
  //

  output {
    display: inline-block;
  }

  summary {
    display: list-item; // Add the correct display in all browsers
  }

  template {
    display: none; // Add the correct display in IE
  }

  // Always hide an element with the `hidden` HTML attribute (from PureCSS).
  // Needed for proper display in IE 10-.
  [hidden] {
    display: none !important;
  }

  mark {
    padding: 0.2em;
    background-color: @yellow;
  }

  ::selection {
    color: @text-color-inverse;
    background: @text-selection-bg;
  }

  color: @text-color;
  font-size: @font-size-base;
  font-family: @font-family;
  font-variant: @font-variant-base;
  line-height: @line-height-base;
  font-feature-settings: @font-feature-settings-base;

  //tailwind preflight styles, added here to prevent bleeding to host page
  *,
  :before,
  :after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
  }

  :before,
  :after {
    --tw-content: '';
  }

  html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    font-family: basis-grotesque;
    font-feature-settings: normal;
    font-variation-settings: normal;
  }

  body {
    margin: 0;
    line-height: inherit;
  }

  hr {
    height: 0;
    color: inherit;
    border-top-width: 1px;
  }

  abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  b,
  strong {
    font-weight: bolder;
  }

  code,
  kbd,
  samp,
  pre {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      Liberation Mono, Courier New, monospace;
    font-size: 1em;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
    background-image: none;
  }

  :-moz-focusring {
    outline: auto;
  }

  :-moz-ui-invalid {
    box-shadow: none;
  }

  progress {
    vertical-align: baseline;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    height: auto;
  }

  [type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  summary {
    display: list-item;
  }

  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }

  fieldset {
    margin: 0;
    padding: 0;
  }

  legend {
    padding: 0;
  }

  ol,
  ul,
  menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  textarea {
    resize: vertical;
  }

  input::placeholder,
  textarea::placeholder {
    opacity: 1;
    color: #9ca3af;
  }

  button,
  [role='button'] {
    cursor: pointer;
  }

  :disabled {
    cursor: default;
  }

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;
    vertical-align: middle;
  }

  img,
  video {
    max-width: 100%;
    height: auto;
  }

  [hidden] {
    display: none;
  }

  *,
  :before,
  :after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-scroll-snap-strictness: proximity;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
  }

  ::-webkit-backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-scroll-snap-strictness: proximity;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
  }

  ::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-scroll-snap-strictness: proximity;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
  }
}
