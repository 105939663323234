.\!container {
  width: 100% !important
}

.container {
  width: 100%
}

@media (min-width: 480px) {
  .\!container {
    max-width: 480px !important
  }

  .container {
    max-width: 480px
  }
}

@media (min-width: 1600px) {
  .\!container {
    max-width: 1600px !important
  }

  .container {
    max-width: 1600px
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.pointer-events-none {
  pointer-events: none
}

.pointer-events-auto {
  pointer-events: auto
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.collapse {
  visibility: collapse
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: sticky
}

.inset-0 {
  inset: 0px
}

.-top-3 {
  top: -0.75rem
}

.bottom-0 {
  bottom: 0px
}

.bottom-3 {
  bottom: 0.75rem
}

.bottom-auto {
  bottom: auto
}

.left-0 {
  left: 0px
}

.left-16 {
  left: 4rem
}

.left-8 {
  left: 32px
}

.right-0 {
  right: 0px
}

.right-1\/2 {
  right: 50%
}

.right-15 {
  right: 60px
}

.right-15\% {
  right: 15%
}

.right-3 {
  right: 0.75rem
}

.right-41\% {
  right: 41%
}

.right-8 {
  right: 32px
}

.right-px {
  right: 1px
}

.top-0 {
  top: 0px
}

.top-1\/2 {
  top: 50%
}

.top-2 {
  top: 8px
}

.top-3 {
  top: 0.75rem
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-50 {
  z-index: 50
}

.z-999 {
  z-index: 999
}

.float-right {
  float: right
}

.float-left {
  float: left
}

.m-0 {
  margin: 0px
}

.m-1 {
  margin: 0.25rem
}

.m-2 {
  margin: 8px
}

.m-4 {
  margin: 16px
}

.m-6 {
  margin: 24px
}

.m-auto {
  margin: auto
}

.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem
}

.-my-px {
  margin-top: -1px;
  margin-bottom: -1px
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px
}

.mx-2 {
  margin-left: 8px;
  margin-right: 8px
}

.mx-4 {
  margin-left: 16px;
  margin-right: 16px
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

.my-2 {
  margin-top: 8px;
  margin-bottom: 8px
}

.mb-0 {
  margin-bottom: 0px
}

.mb-1 {
  margin-bottom: 0.25rem
}

.mb-2 {
  margin-bottom: 8px
}

.mb-3 {
  margin-bottom: 0.75rem
}

.mb-4 {
  margin-bottom: 16px
}

.mb-5 {
  margin-bottom: 20px
}

.mb-6 {
  margin-bottom: 24px
}

.mb-half-header-avatar-size {
  margin-bottom: 60px
}

.ml-1 {
  margin-left: 0.25rem
}

.ml-2 {
  margin-left: 8px
}

.ml-3 {
  margin-left: 0.75rem
}

.mr-0 {
  margin-right: 0px
}

.mr-1 {
  margin-right: 0.25rem
}

.mr-2 {
  margin-right: 8px
}

.mr-3 {
  margin-right: 0.75rem
}

.mr-4 {
  margin-right: 16px
}

.mr-6 {
  margin-right: 24px
}

.mt-2 {
  margin-top: 8px
}

.mt-3 {
  margin-top: 0.75rem
}

.mt-4 {
  margin-top: 16px
}

.mt-6 {
  margin-top: 24px
}

.mt-half-header-avatar-size {
  margin-top: 60px
}

.box-border {
  box-sizing: border-box
}

.\!block {
  display: block !important
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.inline-flex {
  display: inline-flex
}

.table {
  display: table
}

.table-cell {
  display: table-cell
}

.grid {
  display: grid
}

.contents {
  display: contents
}

.list-item {
  display: list-item
}

.hidden {
  display: none
}

.aspect-video {
  aspect-ratio: 16 / 9
}

.h-10 {
  height: 40px
}

.h-11 {
  height: 44px
}

.h-12 {
  height: 48px
}

.h-16 {
  height: 4rem
}

.h-24 {
  height: 96px
}

.h-3 {
  height: 0.75rem
}

.h-30 {
  height: 120px
}

.h-4 {
  height: 16px
}

.h-5 {
  height: 20px
}

.h-6 {
  height: 24px
}

.h-674 {
  height: 674px
}

.h-8 {
  height: 32px
}

.h-80 {
  height: 20rem
}

.h-9 {
  height: 36px
}

.h-auto {
  height: auto
}

.h-fit-content {
  height: fit-content
}

.h-full {
  height: 100%
}

.h-header-avatar-size {
  height: 120px
}

.h-input-height {
  height: 48px
}

.h-input-height-sm {
  height: 36px
}

.h-px {
  height: 1px
}

.max-h-4 {
  max-height: 16px
}

.max-h-96 {
  max-height: 24rem
}

.max-h-\[50vh\] {
  max-height: 50vh
}

.min-h-20 {
  min-height: 80px
}

.min-h-56 {
  min-height: 14rem
}

.min-h-674 {
  min-height: 674px
}

.min-h-\[32px\] {
  min-height: 32px
}

.min-h-\[60px\] {
  min-height: 60px
}

.w-10 {
  width: 40px
}

.w-12 {
  width: 48px
}

.w-13 {
  width: 52px
}

.w-14 {
  width: 3.5rem
}

.w-24 {
  width: 96px
}

.w-30 {
  width: 120px
}

.w-322 {
  width: 322px
}

.w-4 {
  width: 16px
}

.w-4\/5 {
  width: 80%
}

.w-40 {
  width: 160px
}

.w-5 {
  width: 20px
}

.w-50 {
  width: 200px
}

.w-6 {
  width: 24px
}

.w-77 {
  width: 308px
}

.w-78 {
  width: 312px
}

.w-8 {
  width: 32px
}

.w-80 {
  width: 20rem
}

.w-full {
  width: 100%
}

.w-header-avatar-size {
  width: 120px
}

.w-px {
  width: 1px
}

.min-w-0 {
  min-width: 0px
}

.min-w-13 {
  min-width: 52px
}

.min-w-56 {
  min-width: 224px
}

.max-w-\[100px\] {
  max-width: 100px
}

.max-w-full {
  max-width: 100%
}

.max-w-md {
  max-width: 448px
}

.flex-1 {
  flex: 1 1 0%
}

.flex-\[0_0_60px\] {
  flex: 0 0 60px
}

.flex-auto {
  flex: 1 1 auto
}

.flex-initial {
  flex: 0 1 auto
}

.flex-none {
  flex: none
}

.flex-grow {
  flex-grow: 1
}

.border-collapse {
  border-collapse: collapse
}

.origin-top {
  transform-origin: top
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform-none {
  transform: none
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
}

@keyframes spin {
  to {
    transform: rotate(360deg)
  }
}

.animate-spin {
  animation: spin 1s linear infinite
}

.cursor-default {
  cursor: default
}

.cursor-grab {
  cursor: grab
}

.cursor-move {
  cursor: move
}

.cursor-none {
  cursor: none
}

.cursor-not-allowed {
  cursor: not-allowed
}

.cursor-pointer {
  cursor: pointer
}

.resize {
  resize: both
}

.list-none {
  list-style-type: none
}

.appearance-none {
  appearance: none
}

.flex-row {
  flex-direction: row
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-end {
  align-items: flex-end
}

.items-center {
  align-items: center
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.gap-2 {
  gap: 8px
}

.gap-3 {
  gap: 0.75rem
}

.gap-4 {
  gap: 16px
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
}

.divide-border-color-split > :not([hidden]) ~ :not([hidden]) {
  border-color: #0000000d
}

.self-center {
  align-self: center
}

.self-stretch {
  align-self: stretch
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-y-auto {
  overflow-y: auto
}

.overflow-x-hidden {
  overflow-x: hidden
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.overflow-ellipsis {
  text-overflow: ellipsis
}

.whitespace-normal {
  white-space: normal
}

.whitespace-nowrap {
  white-space: nowrap
}

.whitespace-pre-wrap {
  white-space: pre-wrap
}

.break-all {
  word-break: break-all
}

.rounded {
  border-radius: 8px
}

.rounded-full {
  border-radius: 9999px
}

.rounded-lg {
  border-radius: 12px
}

.rounded-md {
  border-radius: 6px
}

.rounded-none {
  border-radius: 0
}

.rounded-sm {
  border-radius: 4px
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.rounded-l-sm {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.rounded-r-sm {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px
}

.rounded-br-lg {
  border-bottom-right-radius: 12px
}

.rounded-tl-lg {
  border-top-left-radius: 12px
}

.rounded-tr-lg {
  border-top-right-radius: 12px
}

.border {
  border-width: 1px
}

.border-0 {
  border-width: 0px
}

.border-2 {
  border-width: 2px
}

.border-b {
  border-bottom-width: 1px
}

.border-l {
  border-left-width: 1px
}

.border-solid {
  border-style: solid
}

.border-dashed {
  border-style: dashed
}

.border-blue-dark {
  --tw-border-opacity: 1;
  border-color: rgb(99 155 178 / var(--tw-border-opacity, 1))
}

.border-border-color-split {
  border-color: #0000000d
}

.border-mustard {
  --tw-border-opacity: 1;
  border-color: rgb(118 112 36 / var(--tw-border-opacity, 1))
}

.border-mustard-400 {
  --tw-border-opacity: 1;
  border-color: rgb(214 212 191 / var(--tw-border-opacity, 1))
}

.border-neutral-200 {
  --tw-border-opacity: 1;
  border-color: rgb(237 239 245 / var(--tw-border-opacity, 1))
}

.border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: rgb(230 232 240 / var(--tw-border-opacity, 1))
}

.border-neutral-400 {
  --tw-border-opacity: 1;
  border-color: rgb(216 218 229 / var(--tw-border-opacity, 1))
}

.border-neutral-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 251 255 / var(--tw-border-opacity, 1))
}

.border-neutral-500 {
  --tw-border-opacity: 1;
  border-color: rgb(193 196 214 / var(--tw-border-opacity, 1))
}

.border-neutral-600 {
  --tw-border-opacity: 1;
  border-color: rgb(143 159 178 / var(--tw-border-opacity, 1))
}

.border-neutral-700 {
  --tw-border-opacity: 1;
  border-color: rgb(105 111 140 / var(--tw-border-opacity, 1))
}

.border-neutral-800 {
  --tw-border-opacity: 1;
  border-color: rgb(71 77 102 / var(--tw-border-opacity, 1))
}

.border-neutral-900 {
  --tw-border-opacity: 1;
  border-color: rgb(16 24 64 / var(--tw-border-opacity, 1))
}

.border-red-base {
  --tw-border-opacity: 1;
  border-color: rgb(255 95 67 / var(--tw-border-opacity, 1))
}

.border-red-dark {
  --tw-border-opacity: 1;
  border-color: rgb(135 20 0 / var(--tw-border-opacity, 1))
}

.border-red-ui {
  --tw-border-opacity: 1;
  border-color: rgb(209 67 67 / var(--tw-border-opacity, 1))
}

.border-transparent {
  border-color: transparent
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1))
}

.border-yellow-dark {
  --tw-border-opacity: 1;
  border-color: rgb(217 184 108 / var(--tw-border-opacity, 1))
}

.border-b-black {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(0 0 0 / var(--tw-border-opacity, 1))
}

.bg-azure {
  --tw-bg-opacity: 1;
  background-color: rgb(213 225 240 / var(--tw-bg-opacity, 1))
}

.bg-azure-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(231 237 245 / var(--tw-bg-opacity, 1))
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity, 1))
}

.bg-blue-light {
  --tw-bg-opacity: 1;
  background-color: rgb(229 248 255 / var(--tw-bg-opacity, 1))
}

.bg-border-color-split {
  background-color: #0000000d
}

.bg-disabled-input-background {
  --tw-bg-opacity: 1;
  background-color: rgb(250 251 255 / var(--tw-bg-opacity, 1))
}

.bg-green-light {
  --tw-bg-opacity: 1;
  background-color: rgb(245 250 250 / var(--tw-bg-opacity, 1))
}

.bg-inherit {
  background-color: inherit
}

.bg-latte {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 232 / var(--tw-bg-opacity, 1))
}

.bg-latte-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 254 248 / var(--tw-bg-opacity, 1))
}

.bg-lilac-base {
  --tw-bg-opacity: 1;
  background-color: rgb(220 180 246 / var(--tw-bg-opacity, 1))
}

.bg-modal-backdrop {
  background-color: #00000080
}

.bg-mustard {
  --tw-bg-opacity: 1;
  background-color: rgb(118 112 36 / var(--tw-bg-opacity, 1))
}

.bg-mustard-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 241 234 / var(--tw-bg-opacity, 1))
}

.bg-mustard-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(214 212 191 / var(--tw-bg-opacity, 1))
}

.bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 252 / var(--tw-bg-opacity, 1))
}

.bg-neutral-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 239 245 / var(--tw-bg-opacity, 1))
}

.bg-neutral-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(230 232 240 / var(--tw-bg-opacity, 1))
}

.bg-neutral-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 251 255 / var(--tw-bg-opacity, 1))
}

.bg-neutral-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(16 24 64 / var(--tw-bg-opacity, 1))
}

.bg-red-light {
  --tw-bg-opacity: 1;
  background-color: rgb(255 246 245 / var(--tw-bg-opacity, 1))
}

.bg-red-ui {
  --tw-bg-opacity: 1;
  background-color: rgb(209 67 67 / var(--tw-bg-opacity, 1))
}

.bg-sand {
  --tw-bg-opacity: 1;
  background-color: rgb(229 203 178 / var(--tw-bg-opacity, 1))
}

.bg-sand-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 250 247 / var(--tw-bg-opacity, 1))
}

.bg-skeleton-row {
  background-color: #bebebe33
}

.bg-terracota-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(238 210 201 / var(--tw-bg-opacity, 1))
}

.bg-transparent {
  background-color: transparent
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1))
}

.bg-yellow-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(217 184 108 / var(--tw-bg-opacity, 1))
}

.bg-yellow-light {
  --tw-bg-opacity: 1;
  background-color: rgb(255 250 224 / var(--tw-bg-opacity, 1))
}

.bg-clip-padding {
  background-clip: padding-box
}

.fill-lilac-base {
  fill: #DCB4F6
}

.fill-neutral-900 {
  fill: #101840
}

.fill-sand-200 {
  fill: #fcfaf7
}

.fill-sand-800 {
  fill: #eadccb
}

.stroke-neutral-900 {
  stroke: #101840
}

.object-cover {
  object-fit: cover
}

.p-0 {
  padding: 0px
}

.p-1 {
  padding: 0.25rem
}

.p-1\.5 {
  padding: 0.375rem
}

.p-2 {
  padding: 8px
}

.p-3 {
  padding: 0.75rem
}

.p-4 {
  padding: 16px
}

.p-5 {
  padding: 20px
}

.p-6 {
  padding: 24px
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px
}

.px-0\.5 {
  padding-left: 0.125rem;
  padding-right: 0.125rem
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem
}

.px-10 {
  padding-left: 40px;
  padding-right: 40px
}

.px-17 {
  padding-left: 68px;
  padding-right: 68px
}

.px-2 {
  padding-left: 8px;
  padding-right: 8px
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.px-4 {
  padding-left: 16px;
  padding-right: 16px
}

.px-5 {
  padding-left: 20px;
  padding-right: 20px
}

.px-6 {
  padding-left: 24px;
  padding-right: 24px
}

.py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem
}

.py-2 {
  padding-top: 8px;
  padding-bottom: 8px
}

.py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.py-4 {
  padding-top: 16px;
  padding-bottom: 16px
}

.py-4\.5 {
  padding-top: 18px;
  padding-bottom: 18px
}

.py-6 {
  padding-top: 24px;
  padding-bottom: 24px
}

.pb-2 {
  padding-bottom: 8px
}

.pb-3 {
  padding-bottom: 0.75rem
}

.pb-4 {
  padding-bottom: 16px
}

.pb-px {
  padding-bottom: 1px
}

.pl-1 {
  padding-left: 0.25rem
}

.pl-3 {
  padding-left: 0.75rem
}

.pl-4 {
  padding-left: 16px
}

.pl-px {
  padding-left: 1px
}

.pr-1 {
  padding-right: 0.25rem
}

.pr-2 {
  padding-right: 8px
}

.pr-4 {
  padding-right: 16px
}

.pr-7 {
  padding-right: 28px
}

.pt-3 {
  padding-top: 0.75rem
}

.pt-3\.5 {
  padding-top: 14px
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.align-top {
  vertical-align: top
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem
}

.text-xxs {
  font-size: 9px
}

.font-bold {
  font-weight: 700
}

.font-medium {
  font-weight: 500
}

.font-normal {
  font-weight: 400
}

.uppercase {
  text-transform: uppercase
}

.italic {
  font-style: italic
}

.leading-5 {
  line-height: 20px
}

.leading-6 {
  line-height: 24px
}

.leading-loose {
  line-height: 2
}

.leading-none {
  line-height: 1
}

.leading-sm {
  line-height: 14px
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1))
}

.text-blue-dark {
  --tw-text-opacity: 1;
  color: rgb(99 155 178 / var(--tw-text-opacity, 1))
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1))
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1))
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity, 1))
}

.text-green-ui {
  --tw-text-opacity: 1;
  color: rgb(82 189 148 / var(--tw-text-opacity, 1))
}

.text-lilac-base {
  --tw-text-opacity: 1;
  color: rgb(220 180 246 / var(--tw-text-opacity, 1))
}

.text-modal-backdrop {
  color: #00000080
}

.text-mustard {
  --tw-text-opacity: 1;
  color: rgb(118 112 36 / var(--tw-text-opacity, 1))
}

.text-mustard-600 {
  --tw-text-opacity: 1;
  color: rgb(186 184 150 / var(--tw-text-opacity, 1))
}

.text-neutral-400 {
  --tw-text-opacity: 1;
  color: rgb(216 218 229 / var(--tw-text-opacity, 1))
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(193 196 214 / var(--tw-text-opacity, 1))
}

.text-neutral-600 {
  --tw-text-opacity: 1;
  color: rgb(143 159 178 / var(--tw-text-opacity, 1))
}

.text-neutral-700 {
  --tw-text-opacity: 1;
  color: rgb(105 111 140 / var(--tw-text-opacity, 1))
}

.text-neutral-800 {
  --tw-text-opacity: 1;
  color: rgb(71 77 102 / var(--tw-text-opacity, 1))
}

.text-neutral-900 {
  --tw-text-opacity: 1;
  color: rgb(16 24 64 / var(--tw-text-opacity, 1))
}

.text-red-base {
  --tw-text-opacity: 1;
  color: rgb(255 95 67 / var(--tw-text-opacity, 1))
}

.text-red-dark {
  --tw-text-opacity: 1;
  color: rgb(135 20 0 / var(--tw-text-opacity, 1))
}

.text-red-ui {
  --tw-text-opacity: 1;
  color: rgb(209 67 67 / var(--tw-text-opacity, 1))
}

.text-terracota {
  --tw-text-opacity: 1;
  color: rgb(218 102 72 / var(--tw-text-opacity, 1))
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1))
}

.text-yellow-dark {
  --tw-text-opacity: 1;
  color: rgb(217 184 108 / var(--tw-text-opacity, 1))
}

.underline {
  text-decoration-line: underline
}

.accent-mustard {
  accent-color: #767024
}

.opacity-0 {
  opacity: 0
}

.opacity-100 {
  opacity: 1
}

.opacity-50 {
  opacity: 0.5
}

.opacity-60 {
  opacity: 0.6
}

.opacity-90 {
  opacity: 0.9
}

.shadow-2 {
  --tw-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0 3px 6px -4px var(--tw-shadow-color), 0 6px 16px 0 var(--tw-shadow-color), 0 9px 28px 8px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-ant-default {
  --tw-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0 3px 6px -4px var(--tw-shadow-color), 0 6px 16px 0 var(--tw-shadow-color), 0 9px 28px 8px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-btn-glow {
  --tw-shadow: 0 0 0 2px #F9FAE8;
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.outline {
  outline-style: solid
}

.outline-1 {
  outline-width: 1px
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.duration-150 {
  transition-duration: 150ms
}

.duration-200 {
  transition-duration: 200ms
}

.ease-custom {
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1)
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1)
}

.placeholder\:text-neutral-500::placeholder {
  --tw-text-opacity: 1;
  color: rgb(193 196 214 / var(--tw-text-opacity, 1))
}

.first\:mt-0:first-child {
  margin-top: 0px
}

.last\:mr-0:last-child {
  margin-right: 0px
}

.only\:hidden:only-child {
  display: none
}

.checked\:border-mustard:checked {
  --tw-border-opacity: 1;
  border-color: rgb(118 112 36 / var(--tw-border-opacity, 1))
}

.checked\:border-neutral-200:checked {
  --tw-border-opacity: 1;
  border-color: rgb(237 239 245 / var(--tw-border-opacity, 1))
}

.checked\:bg-neutral-200:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(237 239 245 / var(--tw-bg-opacity, 1))
}

.checked\:text-neutral-400:checked {
  --tw-text-opacity: 1;
  color: rgb(216 218 229 / var(--tw-text-opacity, 1))
}

.checked\:text-white:checked {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1))
}

.focus-within\:border-neutral-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(71 77 102 / var(--tw-border-opacity, 1))
}

.hover\:scale-100:hover {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.hover\:border-neutral-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(216 218 229 / var(--tw-border-opacity, 1))
}

.hover\:border-neutral-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(193 196 214 / var(--tw-border-opacity, 1))
}

.hover\:border-neutral-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(105 111 140 / var(--tw-border-opacity, 1))
}

.hover\:border-neutral-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(71 77 102 / var(--tw-border-opacity, 1))
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity, 1))
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1))
}

.hover\:bg-item-hover-bg:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity, 1))
}

.hover\:bg-mustard-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 241 234 / var(--tw-bg-opacity, 1))
}

.hover\:bg-neutral-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 252 / var(--tw-bg-opacity, 1))
}

.hover\:bg-neutral-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(237 239 245 / var(--tw-bg-opacity, 1))
}

.hover\:bg-neutral-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(230 232 240 / var(--tw-bg-opacity, 1))
}

.hover\:bg-neutral-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(216 218 229 / var(--tw-bg-opacity, 1))
}

.hover\:bg-neutral-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(105 111 140 / var(--tw-bg-opacity, 1))
}

.hover\:bg-neutral-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(71 77 102 / var(--tw-bg-opacity, 1))
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1))
}

.hover\:bg-opacity-75:hover {
  --tw-bg-opacity: 0.75
}

.hover\:text-mustard:hover {
  --tw-text-opacity: 1;
  color: rgb(118 112 36 / var(--tw-text-opacity, 1))
}

.hover\:text-neutral-700:hover {
  --tw-text-opacity: 1;
  color: rgb(105 111 140 / var(--tw-text-opacity, 1))
}

.hover\:text-neutral-800:hover {
  --tw-text-opacity: 1;
  color: rgb(71 77 102 / var(--tw-text-opacity, 1))
}

.hover\:text-neutral-900:hover {
  --tw-text-opacity: 1;
  color: rgb(16 24 64 / var(--tw-text-opacity, 1))
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1))
}

.hover\:opacity-100:hover {
  opacity: 1
}

.hover\:placeholder\:text-neutral-600:hover::placeholder {
  --tw-text-opacity: 1;
  color: rgb(143 159 178 / var(--tw-text-opacity, 1))
}

.focus\:border-dark-navy:focus {
  --tw-border-opacity: 1;
  border-color: rgb(20 9 54 / var(--tw-border-opacity, 1))
}

.focus\:border-mustard:focus {
  --tw-border-opacity: 1;
  border-color: rgb(118 112 36 / var(--tw-border-opacity, 1))
}

.focus\:border-neutral-900:focus {
  --tw-border-opacity: 1;
  border-color: rgb(16 24 64 / var(--tw-border-opacity, 1))
}

.focus\:bg-dark-navy:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(20 9 54 / var(--tw-bg-opacity, 1))
}

.focus\:bg-mustard-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(214 212 191 / var(--tw-bg-opacity, 1))
}

.focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1))
}

.focus\:text-neutral-900:focus {
  --tw-text-opacity: 1;
  color: rgb(16 24 64 / var(--tw-text-opacity, 1))
}

.focus\:shadow-btn-glow:focus {
  --tw-shadow: 0 0 0 2px #F9FAE8;
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.active\:border-neutral-900:active {
  --tw-border-opacity: 1;
  border-color: rgb(16 24 64 / var(--tw-border-opacity, 1))
}

.active\:border-white:active {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1))
}

.active\:bg-neutral-800:active {
  --tw-bg-opacity: 1;
  background-color: rgb(71 77 102 / var(--tw-bg-opacity, 1))
}

.active\:bg-neutral-900:active {
  --tw-bg-opacity: 1;
  background-color: rgb(16 24 64 / var(--tw-bg-opacity, 1))
}

.active\:text-white:active {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1))
}

.disabled\:border-neutral-300:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(230 232 240 / var(--tw-border-opacity, 1))
}

.disabled\:bg-neutral-50:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(250 251 255 / var(--tw-bg-opacity, 1))
}

.disabled\:text-neutral-500:disabled {
  --tw-text-opacity: 1;
  color: rgb(193 196 214 / var(--tw-text-opacity, 1))
}

.group:hover .group-hover\:underline {
  text-decoration-line: underline
}

.group:hover .group-hover\:opacity-70 {
  opacity: 0.7
}

@media (max-width: 480px) {
  .xs\:w-98vw {
    width: 98vw
  }
}

@media (min-width: 480px) {
  .xxl\:min-w-120 {
    min-width: 480px
  }
}

@media (max-width: 577px) {
  .sm\:mr-0 {
    margin-right: 0px
  }
}

.\[\&\>\*\:last-child\]\:mb-0>*:last-child {
  margin-bottom: 0px
}

.\[\&\>\*\]\:mb-3>* {
  margin-bottom: 0.75rem
}
